@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

:root {
  --clr-green1: #00b47e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-to-top {
  position: fixed;
  bottom: 0;
  right: 0;

  border-radius: 8px;

  margin: 1rem;
  padding: 0.4rem 0.8rem;

  background-color: white;
  color: black;

  box-shadow: 0px 0px 15px #008c5e;

  cursor: pointer;
  z-index: 5;
}

nav {
  transition: 1s;
}