.socials {
    color: white;
    background-color: rgb(26, 21, 31);

    border-radius: 10px 0 0 10px;

    position: fixed;
    right: 0px;
    bottom: 18vh;

    width: max-content;
    padding: 10px;

    line-height: 35px;

    z-index: 100;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.icon a:hover {
    color: var(--clr-purple2);
}