.footerDesc {
    color: #b0b0b0;
    font-size: 1.125rem;
}

.footerLinks {
    color: #b0b0b0;
    text-decoration: none;
    font-size: 1.125rem;
    transition: color 0.3s ease-in-out;
}

.footerLinks:hover {
    color: #00b47e;
}

@media (max-width: 1080px) {
    .footer-wrapper {
        width: 65%;
    }

    footer * {
        text-align: center;
    }

    .footer-grider {
        grid-template-columns: repeat(auto-fill, minmax(14rem, 100%));
        grid-gap: 35px;
    }

    .footer-columns {
        width: 95%;
        align-items: center;
    }

    .footer-columns:nth-child(2) {
        gap: 35px;
    }
}

@media (max-width: 620px) {
    .footer-wrapper {
        width: 95%;
    }
}
