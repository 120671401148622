*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
}

:root {
    --primary: #00b47e;
    --white: #ffffff;
    /* --bg: #f5f5f5; */
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

@media (min-width: 1440px) {
    html {
        zoom: 1.5;
    }
}

@media (min-width: 2560px) {
    html {
        zoom: 1.7;
    }
}

@media (min-width: 3860px) {
    html {
        zoom: 2.5;
    }
}

::-webkit-scrollbar {
    width: 1.3rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #797979;
    transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
    background: #222224;
}

::-webkit-scrollbar-track {
    background: #f9f9f9;
}

.container {
    max-width: 124rem;
    padding: 4rem 1rem;
    margin: 0 auto;
}

.heading {
    padding: 1rem 0;
    text-align: center;
}

.swiper_container {
    height: auto;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide {
    width: 37rem;
    height: 37rem;
    position: relative;
}

@media (max-width: 500px) {
    .swiper_container {
        height: 47rem;
    }

    .swiper-slide {
        width: 28rem !important;
        height: 36rem !important;
    }

    .swiper-slide img {
        width: 28rem !important;
        height: 36rem !important;
    }
}

.swiper-slide img {
    width: 37rem;
    height: 37rem;
    border-radius: 2rem;
    object-fit: cover;
    opacity: 0.6;
    /* Reduced opacity for other cards */
    transition: opacity 0.3s ease;
}

.swiper-slide.swiper-slide-active img {
    opacity: 1;
    /* Full opacity for active card */
}

.card {
    position: relative;
    background-color: #fff;
    border-width: 1px;
    border-color: #00b47e;
    border-radius: 2rem;
    overflow: hidden;
}

.card .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #E8F5E9;
    /* Semi-transparent background for title */
    padding: 1rem;
}

.card h2 {
    margin: 0;
    /* color: #fff; */
    /* Title color */
    font-size: 1.8rem;
    text-align: center;
}

.slider-controls {
    position: absolute;
    bottom: 50%; /* Adjusted position */
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10; /* Ensure controls are above the swiper */
}

.slider-controls .swiper-button-prev,
.slider-controls .swiper-button-next {
    /* background: var(--white); */
    color: #00b47e;
    width: 1rem; /* Reduced size */
    height: 1rem; /* Reduced size */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 1rem; /* Adjusted margin */
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
    transition: background-color 0.3s ease; /* Smooth transition */
}

.slider-controls .swiper-button-prev:hover,
.slider-controls .swiper-button-next:hover {
    /* background: #f0f0f0; Darker background on hover */
}

.slider-controls .swiper-button-prev ion-icon,
.slider-controls .swiper-button-next ion-icon {
    font-size: 1.5rem;
    color: #222224;
}

.swiper-pagination {
    margin-top: 1rem;
    /* width: 35rem !important; */
    text-align: center;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}