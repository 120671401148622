.location-container {
    position: relative;
    width: 100%;
    height: 300px;
    margin: 1rem 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.loading-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    width: 50px;
    height: 50px;
    background-image: url('../../resources/images/loading.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.contact-details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.sub-heading {
    font-size: 2rem;
    color: #333;
}

.contact-details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    width: 100%;
}

.contact-details-item {
    background-color: #E8F5E9;
    border-radius: 1rem;
    padding: 1.5rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-details-item:hover {
    transform: scale(1.05);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.contact-details-item .contact-info {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.contact-details-item .contact-icon {
    font-size: 2rem;
}

@media (max-width: 600px) {
    .loading {
        width: 80% !important;
    }

    .contact-details-grid {
        grid-template-columns: 1fr;
    }
}
